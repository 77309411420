@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url('/fonts/Inter-Light.woff') format('woff');
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url('/fonts/Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url('/fonts/Inter-Medium.woff') format('woff');
}

@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url('/fonts/Inter-Bold.woff') format('woff');
}

body {
  font-family: Inter, sans-serif;
}
